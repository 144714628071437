@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.2/font/bootstrap-icons.min.css");

#content {
  width: 100%;
  transition: all 0.3s;
}

nav {
  a i {
    padding: 8px;
  }
  .dropdown-toggle {
    margin-top: 4px;
  }
}

nav.admin-nav {
  border-bottom: 2px solid grey;
  padding-bottom: 4px;
}

.admin-content {
  margin-top: 8px;
  table.crud {
    th {
      text-align: center;
    }
  }
  .action-bar {
    display: flex;
    margin-bottom: 8px;
  }
}
